import React from "react";
import styled from "styled-components";

import Player, { PlayerContextType } from "legacy-js/react/views/Player/Player";
import { app } from "js/namespaces";
import { browserHistory } from "js/react/history";

const numbersOnlyRegex = /^\d+$/;

const Container = styled.div`
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    position: relative;
    background: black;
`;

interface PlayerContainerProps {
    id?: string;
    slideIndex?: string;
    isPreviewFromEditor?: boolean;
}

function PlayerContainer({ slideIndex, id, isPreviewFromEditor = false }: PlayerContainerProps) {
    const handleExitPlayer = () => {
        const state = browserHistory.location.state as Record<string, any>;
        if (state?.goBackToLibrary) {
            browserHistory.push("/");
            return;
        }
        browserHistory.goBack();
    };

    const match = numbersOnlyRegex.test(slideIndex);
    const sanitizedSlideIndex = (match && Number.isSafeInteger(parseInt(slideIndex)))
        ? (parseInt(slideIndex) - 1)
        : undefined;

    return (<Container>
        <Player
            contextType={PlayerContextType.APP}
            onExit={isPreviewFromEditor ? handleExitPlayer : undefined}
            presentationOrLinkId={id}
            startSlideIndex={sanitizedSlideIndex}
            isPreviewFromEditor={isPreviewFromEditor}
        />
    </Container>);
}

export default PlayerContainer;
